import React from "react"
import classNames from "classnames"

import "./Input.scss"

function Input({
  defaultType,
  value,
  onChange,
  name,
  placeholder,
  className,
  ...rest
}) {
  function handleChange(event) {
    const { value } = event.target

    onChange(value, name)
  }
  const type = defaultType ? defaultType : "input"
  return (
    <div className={classNames("input", className)}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  )
}

export default Input
