import React from "react"
import classNames from "classnames"

import "./Button.scss"

const Button = ({ children, color, addClass, onClick, ...rest }) => {

  const arr= [color, addClass]

  return (
    <button onClick={onClick} className={classNames("button", arr)} {...rest}>
      {children}
    </button>
  )
}

export default Button
