import React from "react"
import "./Checkbox.scss"

let lastId = 0

function Checkbox({ label, checked, onAdd, onDelete, ...rest }) {
  function handleChange(event) {
    if (event.target.checked) {
      onAdd(label)
    } else {
      onDelete(label)
    }
  }
  lastId++
  const attrChecked = checked ? "checked" : ""
  return (
    <div className="checkbox">
      <input
        id={"checkbox" + lastId}
        checked={attrChecked}
        type="checkbox"
        onChange={handleChange}
        {...rest}
      />
      <label htmlFor={"checkbox" + lastId}>{label}</label>
    </div>
  )
}

export default Checkbox
