import React, { useState, useRef } from "react"
import classNames from "classnames"

import "./Select.scss"
import Checkbox from "../Checkbox/Checkbox"
import { useOutsideCatch } from "../../hooks/useOutsideCatch"

function Select({ value, options, color, multiselect, placeholder, onChange }) {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const handleChange = value => {
    onChange(value)
    setMenuOpen(false)
  }
  function handleOpenMenu(event) {
    event.preventDefault()
    event.stopPropagation()
    setMenuOpen(!isMenuOpen)
  }
  function renderOptions() {
    return (
      <div ref={wrapperRef} className="options">
        {options.map(item => {
          return multiselect ? (
            <div>
              <Checkbox label={item} />
            </div>
          ) : (
            <button key={item} onClick={() => handleChange(item)}>
              {item}
            </button>
          )
        })}
      </div>
    )
  }
  const wrapperRef = useRef(null)
  useOutsideCatch(wrapperRef, () => {
    setMenuOpen(false)
  })

  return (
    <div
      className={classNames("select", color, {
        multiselect: multiselect,
        "menu-open": isMenuOpen,
      })}
    >
      {/* <select>
        <option value="">{placeholder}</option>

        {renderOptions()}
      </select> */}

      <button onClick={handleOpenMenu}>{value ? value : placeholder}</button>

      {isMenuOpen && renderOptions()}
    </div>
  )
}

export default Select
